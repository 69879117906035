













































































.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.headIcon {
			img {
				width: 2.4rem;
				height: 2.4rem;
				display: block;
				margin: 0 auto;
			}
		}
.download {
  // background-image: url("../../assets/imgs/share/backGroundImg2.png");
  background-size: 100%;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  background-repeat: no-repeat;

  .shareFooter {
    position: absolute;
    bottom: 1.45rem;
    left: 0;
    width: 100%;

    div {
      width: 50%;
      float: left;

      a,span {
        display: block;
        margin: auto;
        width: 2.54rem;
		    height: 0.8rem;
        border-radius: 5px;
        line-height: 0.8rem;
        font-size: 0.3rem;
        cursor: pointer;
      }

      .iosButton {
        color: #0E39DE;
		    font-weight: 600;
        background: #fff;
		    border-radius: 0.16rem;
      }

      .androidButton {
        color: #0E39DE;
		    font-weight: 600;
        background: #fff;
		    border-radius: 0.16rem;
      }

      img {
        width: 0.4rem;
        vertical-align: -0.1rem;
      }
    }
  }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;