.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.headIcon img {
  width: 2.4rem;
  height: 2.4rem;
  display: block;
  margin: 0 auto;
}
.download {
  background-size: 100%;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  background-repeat: no-repeat;
}
.download .shareFooter {
  position: absolute;
  bottom: 1.45rem;
  left: 0;
  width: 100%;
}
.download .shareFooter div {
  width: 50%;
  float: left;
}
.download .shareFooter div a,
.download .shareFooter div span {
  display: block;
  margin: auto;
  width: 2.54rem;
  height: 0.8rem;
  border-radius: 5px;
  line-height: 0.8rem;
  font-size: 0.3rem;
  cursor: pointer;
}
.download .shareFooter div .iosButton {
  color: #0E39DE;
  font-weight: 600;
  background: #fff;
  border-radius: 0.16rem;
}
.download .shareFooter div .androidButton {
  color: #0E39DE;
  font-weight: 600;
  background: #fff;
  border-radius: 0.16rem;
}
.download .shareFooter div img {
  width: 0.4rem;
  vertical-align: -0.1rem;
}
